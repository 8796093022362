<template>
  <div class="row justify-content-center">
    <customer-header />

    <vs-list>
      <vs-list-item class="k-nav-bar" title="Dashbord" subtitle="">
      </vs-list-item>
    </vs-list>

    <vs-card style="margin-top: 10px" v-if="is_god == 1">
      <vs-list-item
        class="k-nav-bar"
        title="จัดการข้อมูล"
        subtitle=""
        alige="center"
      >
      </vs-list-item>
      <div class="vx-row" style="margin-top: 10px; text-align: right">
        <div class="vx-col w-full" style="text-align: right">
          <vs-button class="mr-2" type="border" color="success">
            <router-link
              tag="div"
              to="/manage_monitoring"
              class="vx-logo cursor-pointer mx-auto flex items-center"
              >monitoring</router-link
            ></vs-button
          >
          <vs-button
            type="border"
            color="primary"
            @click="activePromptClearCarDay = true"
            >เคลียร์รถค้าง</vs-button
          >
          <vs-button
            type="border"
            color="danger"
            @click="activePromptClearTestData = true"
            style="margin-left: 10px"
          >
            ล้างข้อมูล
          </vs-button>
        </div>
      </div>
    </vs-card>

    <vs-card style="margin-top: 10px" v-if="enable_fee == true">
      <vs-list-item
        class="k-nav-bar"
        title="จำนวนเงินที่เก็บได้"
        subtitle=""
        alige="center"
      >
      </vs-list-item>
      <div class="vx-row" style="margin-top: 10px">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="DollarSignIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statAmount_date }}
                    </h2>
                    <p align="center">ยอดวันนี้</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="DollarSignIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-success mb-4"
                      style="background: rgba(var(--vs-success), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statAmount_month }}
                    </h2>
                    <p align="center">ยอดเดือนนี้</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="DollarSignIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-warning mb-4"
                      style="background: rgba(var(--vs-warning), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statAmount_lastMonth }}
                    </h2>
                    <p align="center">ยอดเดือนก่อน</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-card>

    <vs-card>
      <vs-list-item
        class="k-nav-bar"
        title="จำนวนรถเข้าออก"
        subtitle=""
        alige="center"
      >
      </vs-list-item>
      <div class="vx-row" style="margin-top: 10px">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="TruckIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statCar_all }}
                    </h2>
                    <p align="center">
                      ประจำวันทั้งหมด
                      <!-- <vs-button @click="saveFile()" color="primary" type="filled"
          >เพิ่มลูกบ้าน</vs-button> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div slot="no-body">
                  <div class="p-6 pb-0">
                    <div align="center">
                      <feather-icon
                        icon="TruckIcon"
                        class="p-3 inline-flex rounded-full feather-icon select-none relative text-success mb-4"
                        style="background: rgba(var(--vs-success), 0.15)"
                      >
                      </feather-icon>
                    </div>
                    <div class="truncate">
                      <h2 class="mb-1 font-bold" align="center">
                        {{ statCar_out }}
                      </h2>
                      <p align="center">ประจำวันกลับแล้ว</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="TruckIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-warning mb-4"
                      style="background: rgba(var(--vs-warning), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statCar_not_out }}
                    </h2>
                    <p align="center">ยังไม่กลับทั้งหมด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-card>

    <vs-card>
      <vs-list-item
        class="k-nav-bar"
        title="เวลาเฉลี่ยที่อยู่ในโครงการประจำวัน"
        subtitle=""
        alige="center"
      >
      </vs-list-item>
      <div class="vx-row" style="margin-top: 10px">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="WatchIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statTime_all }} นาที
                    </h2>
                    <p align="center">เวลาทั้งหมด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="WatchIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-success mb-4"
                      style="background: rgba(var(--vs-success), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statTime_average | numFormat("0,0.00") }} นาที
                    </h2>
                    <p align="center">เวลาเฉลี่ย</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="WatchIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statTime_short }} นาที
                    </h2>
                    <p align="center">เวลาสั้นที่สุด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="WatchIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-danger mb-4"
                      style="background: rgba(var(--vs-danger), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statTime_long }} นาที
                    </h2>
                    <p align="center">นานที่สุด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-card>

    <div class="vx-row">
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/8 xl:w-1/8 mb-base"
        v-if="isEnableCarType"
      >
        <vx-card title="ประเภทรถที่เข้าออก ประจำวัน">
          <div slot="no-body">
            <vue-apex-charts
              type="pie"
              height="345"
              class="mt-10 mb-10"
              :options="statCarType"
              :series="statCarTypeList.series"
            />
            <!-- CHART DATA -->
            <ul class="mb-1">
              <li
                v-for="statCarTypeList in statCarTypeList.analyticsData"
                :key="statCarTypeList.customerType"
                class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0"
              >
                <span class="flex items-center">
                  <!-- <span
                    class="inline-block h-3 w-3 rounded-full mr-2"
                    :class="`bg-${statCarTypeList.color}`"
                  ></span> -->
                  <span
                    class="font-semibold"
                    :style="{ color: statCarTypeList.color }"
                    >{{ statCarTypeList.carType }}</span
                  >
                </span>
                <span>{{ statCarTypeList.counts }}</span>
              </li>
            </ul>
          </div>
        </vx-card>
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/8 xl:w-1/8 mb-base">
        <vx-card title="วัตถุประสงค์การเข้ามา ประจำวัน">
          <div slot="no-body">
            <vue-apex-charts
              type="pie"
              height="345"
              class="mt-10 mb-10"
              :options="staObjective"
              :series="statObjectiveList.series"
            />
            <!-- CHART DATA -->
            <ul class="mb-1">
              <li
                v-for="statObjectiveList in statObjectiveList.analyticsData"
                :key="statObjectiveList.customerType"
                class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0"
              >
                <span class="flex items-center">
                  <!-- <span
                    class="inline-block h-3 w-3 rounded-full mr-2"
                    :class="`bg-succes`"
                  ></span> -->
                  <span
                    class="font-semibold"
                    :style="{ color: statObjectiveList.color }"
                    >{{ statObjectiveList.objective }}</span
                  >
                </span>
                <span>{{ statObjectiveList.counts }}</span>
              </li>
            </ul>
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card title="สถิติจำนวน รถเข้าออก ย้อนหลัง 7 วัน">
          <div slot="no-body" v-if="bool">
            <vue-apex-charts
              type="bar"
              height="266"
              :options="analyticsCar"
              :series="analyticsCarList"
            />
          </div>
        </vx-card>
      </div>
    </div>

    <vs-card>
      <vs-list-item
        class="k-nav-bar"
        title="สรุป จำนวนรถเข้าออกประจำเดือนปัจจุบัน"
        subtitle=""
        alige="center"
      >
      </vs-list-item>
      <div class="vx-row" style="margin-top: 10px">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="TruckIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-primary mb-4"
                      style="background: rgba(var(--vs-primary), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statMouth }}
                    </h2>
                    <p align="center">เดือนนี้ทั้งหมด</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
          <vx-card class="overflow-hidden">
            <div class="vx-card__collapsible-content vs-con-loading__container">
              <div slot="no-body">
                <div class="p-6 pb-0">
                  <div align="center">
                    <feather-icon
                      icon="TruckIcon"
                      class="p-3 inline-flex rounded-full feather-icon select-none relative text-danger mb-4"
                      style="background: rgba(var(--vs-danger), 0.15)"
                    >
                    </feather-icon>
                  </div>
                  <div class="truncate">
                    <h2 class="mb-1 font-bold" align="center">
                      {{ statLastMouth }}
                    </h2>
                    <p align="center">เดือนก่อน</p>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-card>
    <vs-prompt
      title="ยืนยัน"
      @cancel="clearCarDay = 3"
      @accept="funcao"
      accept-text="ลบ"
      cancel-text="ยกเลิก"
      :active.sync="activePromptClearCarDay"
    >
      <div class="con-exemple-prompt">
        ใส่จำนวนวันที่ต้องการเคลียร์ (ใส่ 0 หากต้องการเคลียร์ทั้งหมด)
        <vs-input type="number" placeholder="จำนวนวัน" v-model="clearCarDay" />
      </div>
    </vs-prompt>

    <vs-prompt
      title="ยืนยัน"
      color="danger"
      @cancel="clearDataConfirmText = ''"
      @accept="clearTestData"
      accept-text="ยืนยัน"
      cancel-text="ยกเลิก"
      :active.sync="activePromptClearTestData"
    >
      <div class="con-exemple-prompt">
        กรุณาพิมพ์ OK แล้วกดยืนยันเพื่อล้างข้อมูล
        <vs-input type="text" placeholder="OK" v-model="clearDataConfirmText" />
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";

const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";
import vSelect from "vue-select";

// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from "vue-apexcharts";
// import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
// import analyticsData from './ui-elements/card/analyticsData.js'
// import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import _color from "@/assets/utils/color.js";
console.log("lang", lang);
import Paginate from "vuejs-paginate";
import numeral from "numeral";
import numFormat from "vue-filter-number-format";

Vue.component("paginate", Paginate);

Vue.use(VueCryptojs);
Vue.use(VueCookies);

Vue.component("customer-header", CustomerHeader);
Vue.component("vue-apex-charts", VueApexCharts);
Vue.component("v-select", vSelect);
Vue.filter("numFormat", numFormat(numeral));

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment,
});

console.log(Vue.moment().locale());
export default {
  name: "ManageDashboard",
  components: {},
  data() {
    return {
      activePromptClearCarDay: false,
      clearCarDay: 3,
      activePromptClearTestData: false,
      clearDataConfirmText: "",
      is_god: false,
      counter: 0,
      isEnableCarType: true,
      iconRight: {
        type: Boolean,
        default: false,
      },
      hideChart: {
        type: Boolean,
        default: false,
      },
      colorTo: {
        type: String,
      },
      arr: [],
      color: "primary",
      color1: "#A9A2F6",
      type: {
        type: String,
        default: "line",
      },
      chartData: {
        // type: Array,
        // required: true
      },
      // Dashbord
      statAmount_date: 0,
      statAmount_month: 0,
      statAmount_lastMonth: 0,
      statCar_all: 0,
      statCar_out: 0,
      statCar_not_out: 0,
      statTime_all: 0,
      statTime_average: 0,
      statTime_short: 0,
      statTime_long: 0,
      statCarType: {
        // labels: ["taxi", "มอเเตอร์ไซด์"],
        labels: [],
        dataLabels: { enabled: false },
        legend: { show: false },
        chart: {
          type: "pie",
          offsetY: 0,
          dropShadow: {
            enabled: false,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2,
          },
          toolbar: { show: false },
        },
        stroke: { width: 5 },
        // colors: ["#7961F9", "#FF9F43", "#EA5455"],
        colors: [],
        fill: {
          type: "gradient",
        },
      },
      statCarTypeList: {},
      staObjective: {
        labels: [],
        dataLabels: { enabled: false },
        legend: { show: false },
        chart: {
          type: "pie",
          offsetY: 0,
          dropShadow: {
            enabled: false,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2,
          },
          toolbar: { show: false },
        },
        stroke: { width: 5 },
        colors: [],
        fill: {
          type: "gradient",
        },
      },
      statObjectiveList: {},
      analyticsCar: {
        grid: {
          borderColor: "#ebebeb",
          padding: { left: 0, right: 0 },
        },
        legend: { show: false },
        dataLabels: { enabled: false },
        chart: { stacked: true, type: "bar", toolbar: { show: false } },
        colors: ["#7367F0", "#EA5455"],
        plotOptions: { bar: { columnWidth: "10%" } },
        xaxis: {
          labels: { style: { cssClass: "text-grey fill-current" } },
          axisTicks: { show: false },
          categories: [],
          // categories2: [
          //   "19/01/2021",
          //   "20/01/2021",
          //   "21/01/2021",
          //   "22/01/2021",
          //   "23/01/2021",
          //   "24/01/2021",
          //   "25/01/2021"
          // ],
          axisBorder: { show: false },
          convertedCatToNumeric: false,
        },
        yaxis: {
          tickAmount: 5,
          labels: { style: { cssClass: "text-grey fill-current" } },
        },
        tooltip: { x: { show: false } },
      },
      analyticsCarList: [],
      statMouth: 0,
      statLastMouth: 0,
      bool: false,
      enable_fee: false,
    };
  },
  computed: {},
  watch: {},
  computed: {},
  methods: {
    async getUserRole(custName, cb) {
      console.log("[[getUserRole]] custName=" + custName);
      await db
        .collection("users")
        .where("customer_name", "==", custName)
        .get()
        .then((snapshots) => {
          snapshots.forEach((doc) => {
            const user = doc.data();
            user.email = doc.id;
            console.log("user, roleInfo=", user);
            this.userInfo = {};
            this.userInfo.user = user;
            if (cb !== null) cb();
          });
        });
    },
    clearTestData: function () {
      // alert("Sou uma funcao");
      if (this.clearDataConfirmText != "OK") return;

      this.clearDataConfirmText = "";

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Confirm",
        text: `ล้างข้อมูลทั้งหมด?"`,
        accept: this.fourceClearData,
        acceptText: "ล้างข้อมูลทั้งหมด !!!",
      });
    },
    funcao: function () {
      // alert("Sou uma funcao");
      var labelText =
        `ต้องการเคลียร์รถที่ค้างมากกว่า ` + this.clearCarDay + ` วันออก?"`;
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: "Confirm",
        text: labelText,
        accept: this.fourceOut,
        acceptText: "เคลียร์",
      });
    },
    refor(n) {
      return n < 10 ? "0" + n : n;
    },
    getDBQuery(_ducumentPath, doc) {
      let reference = db.collection(_ducumentPath);
      if (doc) {
        reference = reference.doc(doc);
      }
      return reference;
    },

    async getHouse() {
      console.log("[[getHouse]]");

      const customerName = "มณีรินทร์ รัตนาธิเบศร์";
      const _documentPath = "customer/" + customerName + "/house/";

      const get = db.collection(_documentPath).orderBy("house_no", "asc");
      get
        .get()
        .then((document) => {
          document.forEach((element) => {
            // const last = document.docs[indexOf];
            const id = element.ref.id;
            const data = element.data();
            console.log("id=", id);
            console.log("data=", data);

            const url =
              "https://v-product.web.app?_uid=" + encodeURIComponent(data.uid);
            this.arr[this.arr.length] = '"' + data.house_no + '","' + url + '"';
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    async fourceClearData() {
      console.log("[[fourceClearData]]");
      const previous = new Date();
      previous.setDate(previous.getDate() - 3);
      console.log("previous=", previous);
      const customerName = $cookies.get("customer");
      console.log("customerName=", customerName);

      // return;
      // const customerName = "zgmx";
      let _documentPath = "customer/" + customerName + "/transactions";
      this.deleteCollection(_documentPath);
      _documentPath = "backup/" + customerName + "/transactions";
      this.deleteCollection(_documentPath);
      _documentPath = "customer/" + customerName + "/upload_photo_queue";
      this.deleteCollection(_documentPath);
      _documentPath = "customer/" + customerName + "/upload_photo_queue_url";
      this.deleteCollection(_documentPath);

      _documentPath = "customer/" + customerName + "/house";
      this.deleteCollection(_documentPath);

      _documentPath = "customer/" + customerName + "/activity";
      this.deleteCollection(_documentPath);

      _documentPath = "customer/" + customerName + "/notify";
      this.deleteCollection(_documentPath);

      _documentPath = "customer/" + customerName + "/request";
      this.deleteCollection(_documentPath);

      _documentPath = "customer/" + customerName + "/vip";
      this.deleteCollection(_documentPath);

      _documentPath =
        "customer/" + customerName + "/scan/data/transaction_round";
      this.deleteCollection(_documentPath);

      const email = this.userInfo.user.email;

      _documentPath = "users/" + email + "/stat";
      this.deleteCollection(_documentPath);
      _documentPath = "users/" + email + "/stat_car_type";
      this.deleteCollection(_documentPath);
      _documentPath = "users/" + email + "/stat_month";
      this.deleteCollection(_documentPath);
      _documentPath = "users/" + email + "/stat_objective";
      this.deleteCollection(_documentPath);

      const data = {};
      data.in_count = 0;
      data.out_count = 0;
      data.waiting_count = 0;

      db.collection("customer")
        .doc(customerName)
        .update(data)
        .then(() => {
          console.log(
            "customerName successfully updated! customerName=" + customerName
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fourceOut() {
      console.log("[[fourceOut]]");

      if (this.isEmpty(this.clearCarDay)) {
        return;
      }

      const previous = new Date();
      previous.setDate(previous.getDate() - this.clearCarDay);
      console.log("previous=", previous);
      const customerName = $cookies.get("customer");
      console.log("customerName=", customerName);
      // const customerName = "zgmx";
      const _documentPath = "customer/" + customerName + "/transactions";
      const _backupDocumentPath = "backup/" + customerName + "/transactions";
      // this.deleteCollection(_documentPath);

      //.where('date_in', '<', TODAY_STARTED_AT)
      const get = db
        .collection(_documentPath)
        .where("date_out", "==", null)
        .where("date_in", "<", previous);
      get
        .get()
        .then((document) => {
          document.forEach((element) => {
            // const last = document.docs[indexOf];
            const id = element.ref.id;
            const data = element.data();
            console.log("id111=", id);
            console.log("data=", data);
            console.log("date_in=", data.date_in.toDate());
            const date_out = data.date_out;
            if (date_out === null) {
              console.log("date_out=null");
            } else {
              console.log("date_out=", data.date_out);
            }

            data.date_out = new Date();
            data.is_fource_out = true;
            data.trans_status = 2;
            data.stamp_type = "ไม่มีตราประทับ";
            //  date.sum_total_time = 0;

            element.ref.update(data)
              .then(() => {
                console.log("User successfully updated! id=" + id);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        })
        .catch((err) => {
          console.log("err", err);
        });

      const get2 = db
        .collection(_documentPath)
        .where("trans_status", "==", 1)
        .where("date_in", "<", previous);
      get2
        .get()
        .then((document) => {
          document.forEach((element) => {
            // const last = document.docs[indexOf];
            const id = element.ref.id;
            const data = element.data();
            console.log("id222=", id);
            console.log("data=", data);
            console.log("date_in=", data.date_in.toDate());
            const date_out = data.date_out;

            if (date_out === null) {
              console.log("date_out=null");
            } else {
              console.log("date_out=", data.date_out);
            }

            data.date_out = new Date();
            data.is_fource_out = true;
            data.trans_status = 2;
            data.stamp_type = "ไม่มีตราประทับ";
            //  date.sum_total_time = 0;

            element.ref.update(data)
              .then(() => {
                console.log("User successfully updated! id=" + id);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        })
        .catch((err) => {
          console.log("err", err);
        });

        const get3 = db
        .collection(_backupDocumentPath)
        .where("date_out", "==", null)
        .where("date_in", "<", previous);
      get3
        .get()
        .then((document) => {
          document.forEach((element) => {
            // const last = document.docs[indexOf];
            const id = element.ref.id;
            const data = element.data();
            console.log("id111=", id);
            console.log("data=", data);
            console.log("date_in=", data.date_in.toDate());
            const date_out = data.date_out;
            if (date_out === null) {
              console.log("date_out=null");
            } else {
              console.log("date_out=", data.date_out);
            }

            data.date_out = new Date();
            data.is_fource_out = true;
            data.trans_status = 2;
            data.stamp_type = "ไม่มีตราประทับ";
            //  date.sum_total_time = 0;

            element.ref.update(data)
              .then(() => {
                console.log("User successfully updated! id=" + id);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        })
        .catch((err) => {
          console.log("err", err);
        });

      const get4 = db
        .collection(_backupDocumentPath)
        .where("trans_status", "==", 1)
        .where("date_in", "<", previous);
      get4
        .get()
        .then((document) => {
          document.forEach((element) => {
            // const last = document.docs[indexOf];
            const id = element.ref.id;
            const data = element.data();
            console.log("id222=", id);
            console.log("data=", data);
            console.log("date_in=", data.date_in.toDate());
            const date_out = data.date_out;
            if (date_out === null) {
              console.log("date_out=null");
            } else {
              console.log("date_out=", data.date_out);
            }

            data.date_out = new Date();
            data.is_fource_out = true;
            data.trans_status = 2;
            data.stamp_type = "ไม่มีตราประทับ";
            //  date.sum_total_time = 0;

            element.ref.update(data)
              .then(() => {
                console.log("User successfully updated! id=" + id);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    async deleteCollection(path) {
      console.log("deleteCollection path=" + path);
      const snapshot = await db.collection(path).get();
      snapshot.docs.forEach((val) => {
        console.log("deleteCollection val=", val);
        val.ref.delete();
      });
    },
    async fourceDelete() {
      console.log("[[fourceDelete]]");
      const previous = new Date();
      previous.setDate(previous.getDate() - 2);
      console.log("previous=", previous);
      const customerName = $cookies.get("customer");
      // const customerName = "The Viridian Home";
      const _documentPath = "backup/" + customerName + "/transactions";
      console.log("[[fourceDelete]] _documentPath=" + _documentPath);
      const get = db.collection(_documentPath).where("date_in", "<", previous);
      get
        .get()
        .then((document) => {
          document.forEach((element) => {
            // const last = document.docs[indexOf];
            const id = element.ref.id;
            const data = element.data();
            console.log("id=", id);
            console.log("data=", data);

            data.date_out = new Date();
            data.is_fource_out = true;
            data.stamp_type = "ไม่มีตราประทับ";
            //  date.sum_total_time = 0;

            // db.collection(_documentPath)
            //         .doc(id)
            //         .delete()
            //         .then(() => {
            //           console.log("User successfully delete! id="+id);
            //         })
            //         .catch((error) => {
            //           console.log(error);
            //         });
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    saveFile: function () {
      const blob = new Blob([this.arr.join("\n")], { type: "text/csv" });
      const e = document.createEvent("MouseEvents"),
        a = document.createElement("a");
      a.download = "test.csv";
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
      e.initEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(e);
    },
    async addHome(home) {
      console.log("[[addHome]] home=", home);

      const customerName = "มณีรินทร์ รัตนาธิเบศร์";
      // const customerName = "Test K-Visit";
      const _documentPath = "customer/" + customerName + "/house/";

      const email = this.isEmpty(home.email) ? "" : home.email;
      const phone = "";
      const name = this.isEmpty(home.name) ? "-" : home.name;
      const house_no = parseInt(home.hid);

      const encryptedText = this.CryptoJS.AES.encrypt(
        customerName + "|" + home.hid + "|",
        "k23qa"
      ).toString();

      // this.formModel.uid = encryptedText;

      // const encryptedText = this.CryptoJS.AES.encrypt(
      //   customerName + "|" + this.formModel.house_no,
      //   "k23qa"
      // ).toString();

      console.log("encryptedText", encryptedText);

      const uid = encryptedText;

      const data = {
        active: true,
        uid: uid,
        email: email,
        house_no: house_no,
        name: name,
        phone: phone,
        prefix_address: "",
      };

      db.collection(_documentPath)
        .doc()
        .set(data)
        .then(() => {
          console.log("addHome successfully updated!");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dateStat(type) {
      if (type === "stat") {
        const nowDate = moment(new Date()).format("DDMMYYYY");
        console.log("nowDate=" + nowDate);
        return nowDate;
        // d = "18012021";
      } else if (type === "stat_month") {
        const nowMonth = moment(new Date()).format("MMYYYY");
        console.log("nowMonth=" + nowMonth);
        // d = "012021";
        return nowMonth;
      } else {
        // last month
        const prevMonth = moment(new Date())
          .subtract(1, "months")
          .format("MMYYYY");
        console.log("prevMonth=" + prevMonth);
        return prevMonth;
      }
    },
    isEmpty(strIn) {
      if (strIn === "undefined") {
        return true;
      } else if (strIn === undefined) {
        return true;
      } else if (strIn === null) {
        return true;
      } else if (strIn === "") {
        return true;
      } else {
        return false;
      }
    },

    async init() {
      const userId = this.userInfo.user.email;
      const statDateId = this.dateStat("stat");
      const statMonthId = this.dateStat("stat_month");
      const lastStatMonthId = this.dateStat("last_stat_month");

      console.log("lastStatMonthId=" + lastStatMonthId);

      const statDoc = await this.getDBQuery(
        "users/" + userId + "/stat/",
        statDateId
      )
        .get()
        .then();
      const statMonthDoc = await this.getDBQuery(
        "users/" + userId + "/stat_month/",
        statMonthId
      )
        .get()
        .then();
      const statLastMonthDoc = await this.getDBQuery(
        "users/" + userId + "/stat_month/",
        lastStatMonthId
      )
        .get()
        .then();
      const statCarType = await this.getDBQuery(
        "users/" + userId + "/stat_car_type/"
      )
        .doc(statDateId)
        .get();
      const statObjective = await this.getDBQuery(
        "users/" + userId + "/stat_objective/"
      )
        .doc(statDateId)
        .get()
        .then();

      const objCarType = statCarType.data();

      let objCarTypeName = null;

      if (objCarType) {
        objCarTypeName = Object.getOwnPropertyNames(objCarType);
      }

      if (objCarTypeName === null) {
        this.isEnableCarType = false;
      }

      const objObjective = statObjective.data();

      console.log("[[objObjective]]=", objObjective);

      let objObjectiveName = null;

      if (objObjective) {
        objObjectiveName = Object.getOwnPropertyNames(objObjective);
      }
      // var obj = {a:1,b:2};
      // console.log(Object.getOwnPropertyNames(obj));

      // console.log("stat ",statDoc.id , statDoc.data());
      console.log("stat month ", statMonthDoc.id, statMonthDoc.data());
      // console.log("statDoc ",statDoc.data());
      // console.log("CarType name", objCarTypeName);
      // console.log("Objective name", objObjectiveName);
      if (statMonthDoc.data()) {
        // console.log("statMonthDoc total_fee ",statMonthDoc.data()['total_fee']);
        const statAmount_month = statMonthDoc.data().total_fee
          ? statMonthDoc.data().total_fee
          : 0;
        this.statAmount_month = this.formatComma(statAmount_month.toString());
        const statMouth = statMonthDoc.data().total_in
          ? statMonthDoc.data().total_in
          : 0;
        this.statMouth = this.formatComma(statMouth.toString());
      }
      if (statLastMonthDoc.data()) {
        const statAmount_lastMonth = statLastMonthDoc.data().total_fee
          ? statLastMonthDoc.data().total_fee
          : 0;
        this.statAmount_lastMonth = this.formatComma(
          statAmount_lastMonth.toString()
        );
        const statLastMouth = statLastMonthDoc.data().total_in
          ? statLastMonthDoc.data().total_in
          : 0;
        this.statLastMouth = this.formatComma(statLastMouth.toString());
      }

      const currentDateStatData = statDoc.data();
      console.log("currentDateStatData=", currentDateStatData);

      if (statDoc.data()) {
        const statAmount_date =
          typeof statDoc.data().total_fee !== "undefined" &&
          statDoc.data().total_fee !== null
            ? statDoc.data().total_fee
            : 0;
        this.statAmount_date = this.formatComma(statAmount_date.toString());
        const statCar_all = statDoc.data().total_in
          ? statDoc.data().total_in
          : 0;
        this.statCar_all = this.formatComma(statCar_all.toString());
        const statCar_out = statDoc.data().total_out
          ? statDoc.data().total_out
          : 0;
        this.statCar_out = this.formatComma(statCar_out.toString());
        //const car_not_out = statDoc.data().total_in - statDoc.data().total_out;
        //this.statCar_not_out =  (car_not_out < 0 ? 0 : car_not_out);
        const statTime_all = statDoc.data().total_time
          ? statDoc.data().total_time
          : 0;
        this.statTime_all = this.formatComma(statTime_all);
        const statTime_average =
          statDoc.data().total_time / statDoc.data().total_in;
        this.statTime_average = statTime_average;
        // this.statTime_average =  this.statTime_average;
        const statTime_short = statDoc.data().total_short_time
          ? statDoc.data().total_short_time
          : 0;
        this.statTime_short = this.formatComma(statTime_short);
        this.statTime_long = this.formatComma(statDoc.data().total_long_time);

        const objDataCar = [];
        const seriesCar = [];

        if (objCarTypeName !== null) {
          for (let i = 0; i < objCarTypeName.length; i++) {
            this.statCarType.labels.push(objCarTypeName[i]);
            // let color = this.getColor();
            const color = this.getColorFix(i);
            this.statCarType.colors.push(color);
            objDataCar.push({
              carType: objCarTypeName[i],
              counts: objCarType[objCarTypeName[i]],
              color: color,
            });
            seriesCar.push(objCarType[objCarTypeName[i]]);
          }
        }

        this.statCarTypeList = {
          analyticsData: objDataCar,
          series: seriesCar,
        };
        // console.log("this.statCarTypeList", this.statCarTypeList);
        // console.log("this.statCarType.labels", this.statCarType.labels);
        // console.log("this.statCarType.colors", this.statCarType.colors);
        const objDataObjective = [];
        const seriesObjective = [];
        console.log("[objObjectiveName]=", objObjectiveName);

        for (let i = 0; i < objObjectiveName.length; i++) {
          // labelsObjective.push(objObjectiveName[i]);
          this.staObjective.labels.push(objObjectiveName[i]);
          // let color = this.getColor();
          const color = this.getColorFix(i);
          this.staObjective.colors.push(color);
          objDataObjective.push({
            objective: objObjectiveName[i],
            counts: objObjective[objObjectiveName[i]],
            color: color,
          });
          seriesObjective.push(objObjective[objObjectiveName[i]]);
        }

        console.log("[objDataObjective]=", objDataObjective);
        console.log("seriesObjective=", seriesObjective);

        this.statObjectiveList = {
          analyticsData: objDataObjective,
          series: seriesObjective,
        };
        // console.log("this.statObjectiveList", this.statObjectiveList);
        // console.log("this.statCarType.labels", this.statCarType.labels);
        // console.log("this.statCarType.colors", this.statCarType.colors);
      }
    },
    getColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getColorFix(index) {
      // const color = ["#6A2CBB","#292b2c","#0275d8","#5cb85c","#5bc0de","#f0ad4e","#806517",'#d9534f','#B8860B','#F0E68C','#90EE90','#DB7093','#708090','#FFFAFA'];
      const color = [
        "#B5179E",
        "#7209B7",
        "#560BAD",
        "#480CA8",
        "#3A0CA3",
        "#3F37C9",
        "#4361EE",
        "#4895EF",
        "#F68E5F",
        "#806517",
        "#B8860B",
        "#F0E68C",
      ];
      return color[index];
    },
    getDays(d, Mention_today = true, id = false) {
      var DateArray = [];
      var days = d;
      for (var i = 0; i < days; i++) {
        if (!Mention_today && i == 0) {
          i = 1;
          days += 1;
        }
        var date = new Date();
        var last = new Date(date.getTime() - i * 24 * 60 * 60 * 1000);
        var day = last.getDate();
        var month = last.getMonth() + 1;
        var year = last.getFullYear();
        let fulld = "";
        if (id) {
          fulld =
            this.refor(Number(day)) +
            "" +
            this.refor(Number(month)) +
            "" +
            Number(year);
        } else {
          fulld =
            this.refor(Number(day)) +
            "/" +
            this.refor(Number(month)) +
            "/" +
            Number(year);
        }
        DateArray.push(fulld);
      }
      return DateArray;
    },
    formatComma(newValue) {
      return newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  components: {
    Datepicker,
  },

  async created() {
    // this.roleInfo = $cookies.get("roleInfo");
    this.is_god = $cookies.get("is_god");
    // this.userInfo = $cookies.get("userInfo");
    // console.log("[[this.userInfo]]", this.userInfo);
    const customerName = $cookies.get("customer");
    console.log("[[customerName]]=" + customerName);
    await this.getUserRole(customerName, null);
    const customerRef = await this.getDBQuery("customer", customerName)
      .get()
      .then();
    const customerData = customerRef.data();

    console.log("customerData", customerData);
    console.log("enable_fee", customerData.enable_fee);
    const userId = this.userInfo.user.email;
    const arrDay = this.getDays(7, true, true);
    const dataValue = [];
    this.enable_fee = customerData.enable_fee;

    this.statCar_not_out = customerData.waiting_count;
    // if (customerRef.data().enable_fee === true) {
    for (let i = 0; i < 7; i++) {
      const statDoc = await this.getDBQuery(
        "users/" + userId + "/stat/",
        arrDay[i]
      )
        .get()
        .then();
      if (statDoc.data()) {
        dataValue[i] = statDoc.data().total_in;
      } else {
        dataValue[i] = 0;
      }
    }
    const arr = {
      name: "สถิติจำนวน รถเข้าออก ย้อนหลัง 7 วัน",
      data: dataValue,
    };
    this.analyticsCarList[0] = arr;
    const days = this.getDays(7, true, false);
    this.analyticsCar.xaxis.categories = days;

    this.init();

    // this.getHouse();
    // this.fourceDelete();

    // for ( let i  =  0; i < this.harr.length ; i++ ){
    //   const item = this.harr[i];
    //   this.addHome({
    //     hid: item[0],
    //     name: item[1],
    //     email: item[2]
    //   });
    // }

    // } else {
    //   let arr = {
    //       name: "สถิติจำนวน รถเข้าออก ย้อนหลัง 7 วัน",
    //       data: [0,0,0,0,0,0,0]
    //   }
    //   this.analyticsCarList[0] = arr;
    //   const days = this.getDays(7, true, false);
    //   this.analyticsCar.xaxis.categories = days;
    // }

    if ($cookies.get("userInfo") === null) {
      this.$router.push("/pages/login").catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }

    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    const parameters = this.$route.query;

    const home = $cookies.get("home");
    console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    console.log("this.houseInfo=", this.houseInfo);
    this.bool = true;
    const n = 5000;
    console.log("COMMA TEST = ", this.formatComma(n.toString()));
  },
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}

.vs-input {
  width: 100%;
  margin-top: 10px;
}

.page-item {
}
</style>
